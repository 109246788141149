<template>
    <Dialog style="display: flex; overflow-x:auto;" modal :header="'Editar ' + datos.datos.nombre" :style="{ width: '1600px'}">
        <div class="card card-primary col-md-4">
            <div class="card-header">
                <h3 class="card-title">Datos</h3>
            </div>
            <div class="card-body">
                <div class="form-group">
                    <label for="nombre">Nombre</label>
                    <input style="width: 100%;" v-model="nombre" type="text" id="nombre">
                </div>
                <div style="margin: 15px 0;">
                    <label for="tipo">Tipo</label>
                    <span style="margin-left: 10px;">{{ datos.datos.tipo }}</span>
                </div>
                <div style="display: flex;">
                    <div class="form-group">
                        <label for="fecha_inicio">Fecha inicio</label>
                        <Calendar dateFormat="dd/mm/yy" style="width: 100%;" v-model="fecha_inicio"/>
                    </div>
                    <div class="form-group" style="margin-left: 15px;">
                        <label for="fecha_fin">Fecha fin</label>
                        <Calendar dateFormat="dd/mm/yy" style="width: 100%;" v-model="fecha_fin"/>
                    </div>
                </div>
                <div>
                    <button @click="guardarDatos" class="btn btn-success" style="width: 100%;">Guardar</button>
                </div>
            </div>
        </div>
        <div class="card card-secondary col-md-12">
            <div class="card-header">
                <h3 class="card-title">Trabajos</h3>
            </div>
            <div class="card-body">
                <div class="card">
                    <div class="card-header">
                        <h3>Nuevo</h3>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table-sm">
                                <thead>
                                    <tr>
                                        <th>Código</th>
                                        <th>Cód. Relación</th>
                                        <th>Nombre</th>
                                        <th>Precio Cl.</th>
                                        <th>Precio Oper.</th>
                                        <th>Precio libre</th>
                                        <th>Solo Oper.</th>
                                        <th>App.</th>
                                        <th>Cantidad fija</th>
                                        <th>Tipo.</th>
                                        <th>Especialidad</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><input style="width: 140px;" v-model="codigo" type="text"></td>
                                        <td><input style="width: 120px;" v-model="codrel" type="text"></td>
                                        <td><input style="width: 190px;" v-model="nomtr" type="text"></td>
                                        <td><input style="width: 120px;" v-model="preciocl" type="text"></td>
                                        <td><input style="width: 120px;" v-model="preciooper" type="text"></td>
                                        <td><input v-model="preciolibre" type="checkbox"></td>
                                        <td><input v-model="solooper" type="checkbox"></td>
                                        <td><input v-model="app" type="checkbox"></td>
                                        <td><input style="width: 120px;" v-model="cantidad" type="text"></td>
                                        <td>
                                            <select v-model="tipo" id="">
                                                <option value="">---</option>
                                                <option value="MAT">MAT</option>
                                                <option value="MO">MO</option>
                                                <option value="DES">DES</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select v-model="especialidad">
                                                <option value="">Seleccionar</option>
                                                <option v-for="especialidad in especialidades" :key="especialidad.id" :value="especialidad.id">{{ especialidad.nombre }}</option>
                                            </select>
                                        </td>
                                        <td><button @click="anadirTrabajo" class="btn btn-sm btn-success">Añadir</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-end align-items-center">
                <div>
                    <label for="buscar">Buscar: </label>
                </div>
                <div style="margin-left: 10px;">
                    <input type="search" name="buscar" class="form-control" v-model="globalFilter" aria-controls="tabla_chat"> 
                </div>
            </div>
            <DataTable 
            stripedRows
            v-model:filters="filters" 
            :value="trabajosFiltrados"
            responsiveLayout="scroll"
            paginator 
            :rows="20" 
            dataKey="idtrabajo" 
            :globalFilterFields="['codigo', 'nombre', 'nombre_especialidad']">
            
                <template #empty> No hay baremos disponibles. </template>

                <!-- Column Definitions -->
                <Column>
                    <template #header>
                        <div class="d-flex justify-content-between">
                            <strong>Código</strong>
                            <button @click="toggleOrden('codigo')" :aria-sort="codigoOrdenAscendente ? 'ascending' : 'descending'" class="btn btn-sm">
                                <i :class="codigoIcon"></i>
                            </button>
                        </div>
                    </template>
                    <template #body="{ data }">
                        <input style="width: 140px;" v-model="codigotr[data.idtrabajo]" type="text">
                    </template>
                </Column>
                
                <Column>
                    <template #header>
                        <div class="d-flex justify-content-between">
                            <strong>Cod. Relación</strong>
                        </div>
                    </template>
                    <template #body="{ data }">
                        <input style="width: 120px;" v-model="codreltr[data.idtrabajo]" type="text">
                    </template>
                </Column>
                
                <Column>
                    <template #header>
                        <div class="d-flex justify-content-between">
                            <strong>Nombre</strong>
                            <button @click="toggleOrden('nombre')" :aria-sort="nombreOrdenAscendente ? 'ascending' : 'descending'" class="btn btn-sm">
                                <i :class="nombreIcon"></i>
                            </button>
                        </div>
                    </template>
                    <template #body="{ data }">
                        <div style="width: 190px;">
                            {{ data.nombre }}
                        </div>
                    </template>
                </Column>

                <Column>
                    <template #header>
                        <div class="d-flex justify-content-between">
                            <strong>Precio Cl.</strong>
                        </div>
                    </template>
                    <template  #body="{ data }">
                        <input style="width: 120px;" v-model="preciocltr[data.idtrabajo]" type="text">
                    </template>
                </Column>

                <Column>
                    <template #header>
                        <div class="d-flex justify-content-between">
                            <strong>Precio Oper.</strong>
                        </div>
                    </template>
                    <template #body="{ data }">
                        <input style="width: 120px;" v-model="precioopertr[data.idtrabajo]" type="text">
                    </template>
                </Column>

                <Column>
                    <template #header>
                        <div class="d-flex justify-content-between">
                            <strong>Precio libre</strong>
                        </div>
                    </template>
                    <template style="text-align: center;" #body="{ data }">
                        <input v-model="preciolibretr[data.idtrabajo]" type="checkbox">
                    </template>
                </Column>

                <Column>
                    <template #header>
                        <div class="d-flex justify-content-between">
                            <strong>Spolo Oper.</strong>
                        </div>
                    </template>
                    <template style="text-align: center;" #body="{ data }">
                        <input v-model="soloopertr[data.idtrabajo]" type="checkbox">
                    </template>
                </Column>

                <Column>
                    <template #header>
                        <div class="d-flex justify-content-between">
                            <strong>App</strong>
                        </div>
                    </template>
                    <template style="text-align: center;" #body="{ data }">
                        <input v-model="apptr[data.idtrabajo]" type="checkbox">
                    </template>
                </Column>

                <Column>
                    <template #header>
                        <div class="d-flex justify-content-between">
                            <strong>Cantidad fija</strong>
                        </div>
                    </template>
                    <template #body="{ data }">
                        <input style="width: 120px;" v-model="cantidadtr[data.idtrabajo]" type="text">
                    </template>
                </Column>

                <Column>
                    <template #header>
                        <div class="d-flex justify-content-between">
                            <strong>Tipo</strong>
                        </div>
                    </template>
                    <template #body="{ data }">
                        <select v-model="tipotr[data.idtrabajo]" id="">
                            <option value="">---</option>
                            <option value="MAT">MAT</option>
                            <option value="MO">MO</option>
                            <option value="DES">DES</option>
                        </select>
                    </template>
                </Column>

                <Column>
                    <template #header>
                        <div class="d-flex justify-content-between">
                            <strong>Especialidad</strong>
                        </div>
                    </template>
                    <template style="text-align: center;" #body="{ data }">
                        {{ data.nombre_especialidad}}
                    </template>
                </Column>

                <Column>
                    <template #header>
                    </template>
                    <template #body="{ data }">
                        <button class="btn btn-sm btn-success" @click="guardar(data.idtrabajo)">Guardar</button>
                        <button style="margin-top: 10px;" class="btn btn-sm btn-danger" @click="eliminar(data)">Eliminar</button>
                    </template>
                </Column>

            </DataTable> 
        </div>
    </Dialog>
</template>
<script>
import DataTable from 'primevue/datatable';
import Calendar from 'primevue/calendar';
import Dialog from 'primevue/dialog';
import { PwgsApi } from '../../../../services/PwgsApi';
import { FilterMatchMode } from 'primevue/api';
export default {
    props:["id", "datos"],
    data(){
        return{
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            globalFilter:null,
            especialidades:[],
            nombre:'',
            fecha_inicio:'',
            fecha_fin:'',
            codigo:'',
            codrel:'',
            nomtr:'',
            preciocl:'',
            preciooper:'',
            preciolibre:false,
            solooper:false,
            app:false,
            cantidad:'',
            tipo:'',
            especialidad:'',
            trabajos:[],
            ordenarPor: 'codigo',
            codigotr:[],
            codreltr:[],
            nombretr:[],
            preciocltr:[],
            precioopertr:[],
            preciolibretr:[],
            soloopertr:[],
            apptr:[],
            cantidadtr:[],
            tipotr:[],
            especialidadtr:[],
            codigoOrdenAscendente:true,
            codigoIcon: 'fas fa-sort-down',
            nombreOrdenAscendente:false,
            nombreIcon: 'fas fa-sort-up',
        }
    },
    components:{
        DataTable,
        Calendar,
        Dialog,
    },
    computed:{
        trabajosFiltrados(){
            if(this.trabajos){
                let filtrados = this.trabajos;
                 filtrados.sort((a, b) => {
                    if(this.ordenarPor == 'codigo') {                        
                        const valorA = a.codigo.toLowerCase();
                        const valorB = b.codigo.toLowerCase();
                        return this.codigoOrdenAscendente ? valorB.localeCompare(valorA) : valorA.localeCompare(valorB);                       
                    }else if(this.ordenarPor == 'nombre') {
                        const valorA = a.nombre.toLowerCase();
                        const valorB = b.nombre.toLowerCase();
                        return this.nombreOrdenAscendente ? valorB.localeCompare(valorA) : valorA.localeCompare(valorB); 
                    }
                });
                return filtrados;
            }else{
                return [];
            }
        }
    },
    methods:{
        async guardar(idtrabajo){
            const api = new PwgsApi();
            await api.put('baremos-pwgs/'+this.id+'/trabajo/'+idtrabajo,{codigo: this.codigotr[idtrabajo], precio:this.preciocltr[idtrabajo],
                cantidad: this.cantidadtr[idtrabajo], precio_operario: this.precioopertr[idtrabajo], libre_trabajo: this.preciolibretr[idtrabajo] ? 1:0,
                destinatario: this.soloopertr[idtrabajo] ? 'operario':'', codigo_relacion: this.codreltr[idtrabajo], app: this.apptr[idtrabajo]?1:0,
                tipo: this.tipotr[idtrabajo]});
            alert('Trabajo modificado');
            this.obtenerTrabajos();
            
        },
        async eliminar(trabajo){
            const api = new PwgsApi();
            await api.delete('baremos-pwgs/'+this.id+'/trabajo/'+trabajo.idtrabajo);
            this.obtenerTrabajos();
        },
        async anadirTrabajo(){
            const api = new PwgsApi();
            if(this.nomtr == ''){
                alert('Debe introducir un nombre');
                return;
            }
            if(this.especialidad == ''){
                alert('Debe seleccionar una especialidad');
                return;
            }
            await api.post('baremos-pwgs/'+this.id+'/trabajo',{id_especialidad: this.especialidad, codigo:this.codigo, precio:this.preciocl, cantidad:this.cantidad,
                precio_operario:this.preciooper, libre_trabajo: this.preciolibre ? 1:0, destinatario: this.solooper ? 'operario':'', codigo_relacion: this.codrel,
                app: this.app ? 1:0, tipo: this.tipo, nombre:this.nomtr});
            this.codigo='';
            this.codrel = '';
            this.nomtr='',
            this.preciocl='',
            this.preciooper='',
            this.preciolibre=false,
            this.solooper=false,
            this.app=false,
            this.cantidad='',
            this.tipo='',
            this.especialidad='',
            this.obtenerTrabajos();
        },
        toggleOrden(campo) {
            this.ordenarPor = campo;
            if(campo == 'codigo'){
                this.codigoOrdenAscendente = !this.codigoOrdenAscendente;
                this.codigoIcon = this.codigoOrdenAscendente ? 'fas fa-sort-down' : 'fas fa-sort-up';
                this.nombreOrdenAscendente = false;
                this.nombreIcon = 'fas fa-sort-up'             
            }else if(campo == 'nombre'){
                this.nombreOrdenAscendente = !this.nombreOrdenAscendente;
                this.nombreIcon = this.nombreOrdenAscendente ? 'fas fa-sort-down' : 'fas fa-sort-up';
                this.codigoOrdenAscendente = false;
                this.codigoIcon = 'fas fa-sort-up'
            }
        },
        async guardarDatos(){
            const api = new PwgsApi();
            await api.put('baremos-pwgs/'+this.id, {nombre:this.nombre, fecha_inicio:(this.fecha_inicio.toLocaleDateString()), fecha_fin: (this.fecha_fin.toLocaleDateString())});
            this.$emit('datos-actualizados');
            this.$emit('update:visible')
        },
        datosIniciales(){
            this.nombre = this.datos.datos.nombre;
            this.fecha_inicio = this.parseDate(this.datos.datos.fecha_inicio);
            this.fecha_fin = this.parseDate(this.datos.datos.fecha_fin);
        },
        parseDate(date){
            const [ano, mes, dia] = date.split("-");
            return `${dia}/${mes}/${ano}`;
        },
        async obtenerTrabajos(){
            const api = new PwgsApi();
            var espes = await api.get('/especialidades');
            this.especialidades = espes.datos;
            const trs = await api.post('baremos-pwgs/'+this.id+'/trabajos');
            this.trabajos = Object.values(trs);
            for(let trab of this.trabajos){
                var id = trab.idtrabajo;
                this.codigotr[id]=trab.codigo; 
                this.codreltr[id]=trab.codigo_relacion;
                this.nombretr[id]=trab.nombre;
                this.preciocltr[id]=trab.precio;
                this.precioopertr[id]=trab.precio_operario;
                this.preciolibretr[id]=trab.libre_trabajo=='1'?true:false;
                this.soloopertr[id]=trab.destinatario=='operario'?true:false;
                this.apptr[id]=trab.app=='1'?true:false;
                this.cantidadtr[id]=trab.cantidad;
                this.tipotr[id]=trab.tipo;
                this.especialidadtr[id]=trab.nombre_especialidad;
            } 
        }
    },
    mounted(){
        this.datosIniciales();
        this.obtenerTrabajos();
    },
    watch: {
        globalFilter(newFilter) {
            this.filters.global.value = newFilter;
        }
    }
}
</script>